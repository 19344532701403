<template>
  <div class="heading_area">
    <div class="container">
      <div class="heading_body_area">
        <h1>
          {{ transCityName
          }}<span class="icon"><i class="fas fa-map-marker-alt"></i></span>
        </h1>
        <h2>
          {{ transHotelName
          }}<span class="icon"><i class="fas fa-hotel"></i></span>
        </h2>
        <h3>
          {{ periodVocation
          }}<span class="icon"><i class="far fa-calendar-alt"></i></span>
        </h3>
        <h3>
          {{ paxList }}<span class="icon"><i class="fas fa-user"></i></span>
        </h3>
        <!-- <h3 v-if="product.category.dealTypeCode === 'SportPack'">
          {{ remark }}<span class="icon"><i class="fas fa-ticket-alt"></i></span>
        </h3> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      periodVocation: '',
      paxList: '',

      transCityName: '',
      transHotelName: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    remark() {
      // return this.product?.remarkTranslation?.[this.lang] ?? this.product?.remark ?? '';
      return this.product.remark ?? '';
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    const from = dayjs(this.product.fromDate),
      to = from.add(this.product.duration_1, 'day');
    this.periodVocation = `${from.format('DD/MM/YYYY')}-${to.format('DD/MM/YYYY')}`;
    const { roomList } = JSON.parse(window.sessionStorage.getItem('roomList'));
    let ctAdult = 0, ctChild = 0, ctInfant = 0;
    roomList.forEach((room) => {
      ctAdult += room.adult;
      ctChild += room.child;
      ctInfant += room.infant;
    });
    if (ctAdult) this.paxList += `${this.$t('booking.adults')} ${ctAdult}`;
    if (ctChild) this.paxList += ` | ${this.$t('booking.children')} ${ctChild}`;
    if (ctInfant) this.paxList += ` | ${this.$t('booking.infants')} ${ctInfant}`;

    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      // eslint-disable-next-line no-mixed-operators
      this.transCityName = this.product.translations?.city && this.product.translations?.city[this.product.hotels[0].city][this.lang] || this.product.hotels[0]?.cityName;
      this.transHotelName = this.product.hotels[0]?.hotelName; // this.product.translations.hotelName && this.product.translations.hotelName[this.product.hotels[0].La_ID][this.lang] || this.product.hotels[0].hotelName;
    },
  },
};
</script>

<style scoped>
.heading_area {
  width: 100%;
  padding: 20px 0px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}
.heading_area .heading_body_area h1 {
  color: #ffffff;
  font-size: 16px;
  margin: 0 0 6px;
  letter-spacing: normal;
  font-weight: 600;
  position: relative;
  padding-right: 20px;
}
.heading_area .heading_body_area h1 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.heading_area .heading_body_area h2 {
  color: #ffffff;
  font-size: 16px;
  margin: 0 0 6px;
  letter-spacing: normal;
  font-weight: 600;
  position: relative;
  padding-right: 20px;
}
.heading_area .heading_body_area h2 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.heading_area .heading_body_area h3 {
  color: #ffffff;
  font-size: 14px;
  margin: 0 0 6px;
  letter-spacing: normal;
  font-weight: 400;
  position: relative;
  padding-right: 20px;
}
.heading_area .heading_body_area h3 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.heading_area .heading_body_area h3:last-child {
  margin-bottom: 0px;
}
</style>
